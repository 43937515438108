import LinkedIn from "../../assets/sosmed/LinkedIn.svg";
import Medium from "../../assets/sosmed/medium.svg";
import Instagram from "../../assets/sosmed/Instagram.svg";

const sosmed = [
  {
    src: LinkedIn,
    href: "https://www.linkedin.com/in/suryapersada",
    alt: "LinkedIn",
  },
  { src: Medium, href: "https://medium.com/@suryapsd", alt: "Medium" },
  {
    src: Instagram,
    href: "https://www.instagram.com/cosu.co/",
    alt: "Instagram",
  },
];

const BtnSosmed = () => {
  return (
    <div className="flex flex-wrap justify-center lg:justify-end">
      {sosmed.map((item) => (
        <a
          target="_blank"
          href={item.href}
          className="transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-300 hover:shadow-xl mx-2 rounded-md scale-100"
        >
          <img src={item.src} alt={item.alt} />
        </a>
      ))}
    </div>
  );
};

export default BtnSosmed;
