import Hero from '../components/Hero';
import About from '../components/About';
import Footer from '../components/Footer';
import Portofolio from '../components/Portofolio';
import ScrollButton from '../components/button/ScrollButton';
import Awards from '../components/Awards';

const Beranda = (props) => {
    return (
      <div className='bg-background'>
        <div>
          <Hero/>
          <About/>
          <Portofolio/>
          <Awards/>
          <ScrollButton/>
        </div>
        <Footer/>
      </div>
    );
  };
  
  export default Beranda;
  