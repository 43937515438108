const CardAbout = ({image, title, desc}) => {
  return (
    <div className="card w-52 lg:w-56 md:w-48 px-4 py-2 lg:py-5 md:py-5 justify-center bg-gradient-to-b from-slate-50/10 border-2 border-indigo-900/50 shadow-md rounded-3xl backdrop-blur-xl lg:mx-5 md:mx-2 mt-4 transition ease-in-out hover:-translate-y-1 duration-300 hover:shadow-2btn hover:shadow-blue500/50">
        <div className="w-full flex justify-center">
            <img src={image} alt={title}/>
        </div>
        <div className="">
            <h1 className="text-lg lg:text-xl font-medium text-white">{title}</h1>
            <div className="lg:py-1 py-3 flex justify-center">
                <svg width="94" height="3" viewBox="0 0 94 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="0.5" y1="1.78003" x2="93.5" y2="1.78003" stroke="url(#paint0_linear_120_80)" stroke-width="2"/>
                <defs>
                <linearGradient id="paint0_linear_120_80" x1="0.5" y1="3.78014" x2="93.5" y2="3.78014" gradientUnits="userSpaceOnUse">
                <stop stop-color="white" stop-opacity="0"/>
                <stop offset="0.511639" stop-color="white" stop-opacity="0.5"/>
                <stop offset="1" stop-color="white" stop-opacity="0"/>
                </linearGradient>
                </defs>
                </svg>
            </div>
            <p className="text-sm lg:text-sm font-normal text-slate-200">{desc}</p>
        </div>
    </div>
  );
};

export default CardAbout;
