//import Button from "../components/button/BtnPrimary";
import CardPortofolio from "./CardPortofolio";
import MomCare from "../assets/images/MomCare.webp";
import EduGam from "../assets/images/EduGam.webp";
import Anition from "../assets/images/Anition.webp";
import DSKILL from "../assets/images/DSKILL.webp";
import DesaTaro from "../assets/images/DesaTaro.webp";
import Nampah from "../assets/images/Nampah.webp";
import Maidental from "../assets/images/Maidental.webp";
import Jago from "../assets/images/Jago.webp";
import Jagabumi from "../assets/images/Jaga Bumi.webp";

const portofolio = [
  {
    src: MomCare,
    href: "https://www.figma.com/proto/GRnWLEaC7fFPY8YvOmxjb1/MomCare?page-id=326%3A5080&node-id=339%3A13412&viewport=1464%2C1952%2C0.9&scaling=scale-down&starting-point-node-id=339%3A13412&show-proto-sidebar=1",
    title: "Mom Care",
    tag: "UI/UX Design",
    desc: "Application to diagnose disease in pregnancy.",
    alt: "Mom Care",
  },
  {
    src: Jago,
    href: "https://www.figma.com/proto/eLSNyhzapvbDKaJKj07w4w/Wireframe-Bank-Jago?page-id=271%3A2533&node-id=272%3A5353&viewport=422%2C280%2C0.09&scaling=scale-down&starting-point-node-id=271%3A2753&show-proto-sidebar=1",
    title: "Last Wish",
    tag: "UI/UX Design",
    desc: "Make your wish with Jago Last Wish by Bank Jago.",
    alt: "Medium",
  },
  {
    src: EduGam,
    href: "https://www.figma.com/proto/xua3PFmxfDKLUXSM4LUoTO/Wireframe?page-id=359%3A1626&node-id=436%3A3569&viewport=-589%2C1125%2C0.37&scaling=scale-down&starting-point-node-id=436%3A2217&show-proto-sidebar=1",
    title: "EduGam",
    tag: "UI/UX Design",
    desc: "Online Course Application by Applying Gamification.",
    alt: "EduGam",
  },
  {
    src: Jagabumi,
    href: "https://www.figma.com/proto/C92D1r9qq9fT9mLS2hUvue/UI-Design?page-id=15%3A180&node-id=52%3A7399&viewport=398%2C432%2C0.08&scaling=scale-down&starting-point-node-id=148%3A4452",
    title: "Jaga Bumi",
    tag: "UI/UX Design",
    desc: "Educational application about Global Warming for Kids and Teens.",
    alt: "Jaga Bumi",
  },
  {
    src: Maidental,
    href: "https://maidental.pqrs.space/",
    title: "Mai Dental",
    tag: "Web Design",
    desc: "Eye Disease Diagnostic Expert System Website.",
    alt: "Mai Dental",
  },
  {
    src: DSKILL,
    href: "https://www.figma.com/proto/9DlbQPtQCuQEKnmC71M0em/DSkill?page-id=18%3A59&node-id=45%3A369&viewport=373%2C127%2C0.14&scaling=scale-down&starting-point-node-id=687%3A8507&show-proto-sidebar=1",
    title: "DSkill",
    tag: "UI/UX Design",
    desc: "Disability-friendly online course and job finder application.",
    alt: "DSkill",
  },
  {
    src: Nampah,
    href: "https://www.figma.com/proto/NEdMMAIBKBIgZEfSRQozs0/RPL?page-id=0%3A1&node-id=8%3A2&viewport=-1381%2C369%2C0.29&scaling=min-zoom&starting-point-node-id=8%3A2&show-proto-sidebar=1",
    title: "Nampah",
    tag: "UI/UX Design",
    desc: "Waste bank website to handle the increasing amount of trash.",
    alt: "Nampah",
  },
  {
    src: DesaTaro,
    href: "https://www.figma.com/proto/2lTaTu76mCzrFksD6vh8gm/Untitled?page-id=0%3A1&node-id=2%3A15&viewport=-707%2C300%2C0.35&scaling=min-zoom&starting-point-node-id=2%3A15",
    title: "Desa Taro",
    tag: "UI/UX Design",
    desc: "Website for booking tourist village tickets.",
    alt: "Desa Taro",
  },
  {
    src: Anition,
    href: "https://www.figma.com/proto/9TqrNyle4eWlmeJczsXtfQ/Anition?page-id=0%3A1&node-id=55%3A3&viewport=34093%2C5653%2C7.93&scaling=min-zoom&starting-point-node-id=55%3A3&show-proto-sidebar=1",
    title: "Anition",
    tag: "UI/UX Design",
    desc: "Animal adoption website to reduce abandoned wild animals.",
    alt: "Anition",
  },
];

const Portofolio = () => {
  return (
    <section id="portofolio" className="lg:min-h-screen mt-24 lg:mt-12">
      <div className="flex flex-wrap justify-center">
        <div className="absolute mt-52 py-24 px-24 lg:py-40 lg:px-40 rounded-full blur-bg3 bg-blue500"></div>
      </div>
      <div className="lg:min-h-screen mx-2 lg:px-20">
        <div data-aos="fade-up" className="justify-center aos-init aos-animate">
          <h1 className="z-10 font-medium text-slate-300 text-xl lg:text-2xl">
            Project
          </h1>
          <div className="flex justify-center mt-2 text-gray-500">
            <svg
              width="94"
              height="3"
              viewBox="0 0 94 3"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="0.5"
                y1="1.78088"
                x2="93.5"
                y2="1.78088"
                stroke="url(#paint0_linear_120_69)"
                stroke-width="2"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_120_69"
                  x1="0.5"
                  y1="3.78099"
                  x2="93.5"
                  y2="3.78099"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="white" stop-opacity="0" />
                  <stop
                    offset="0.511639"
                    stop-color="white"
                    stop-opacity="0.5"
                  />
                  <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <h1 className="text-3xl lg:text-4xl font-bold text-white mt-3">
            My Creative Portofolio
          </h1>
          <p className="mt-3 text-slate-300 text-md lg:text-lg mx-2 lg:mx-56 md:mx-40">
            Let's take a look at some of the best projects I've made.
          </p>
        </div>
        <div
          data-aos="fade-up"
          className="flex flex-wrap justify-center aos-init aos-animate"
        >
          <div className="lg:hidden carousel rounded-box py-6">
            {portofolio.map((item) => (
              <div className="carousel-item mx-2 lg:mx-0">
                <CardPortofolio
                  href={item.href}
                  image={item.src}
                  title={item.title}
                  tag={item.tag}
                  desc={item.desc}
                  alt={item.alt}
                />
              </div>
            ))}
          </div>
          <div className="hidden lg:flex flex-wrap justify-center pb-16">
            {portofolio.map((item) => (
              <div className="my-4">
                <CardPortofolio
                  href={item.href}
                  image={item.src}
                  title={item.title}
                  tag={item.tag}
                  desc={item.desc}
                  alt={item.alt}
                />
              </div>
            ))}
          </div>
        </div>
        {/* <div data-aos="fade-up" className="mt-2 pb-12 aos-init aos-animate">
          <Button button={"See More..."} link={"#"} />
        </div> */}
      </div>
    </section>
  );
};

export default Portofolio;
