import Profile from '../assets/images/image.webp';
import Point from '../assets/images/point.svg';
import Patern from '../assets/images/patern.svg';
import Figma from '../assets/images/figma.webp';
import BtnSosmed from './button/BtnSosmed';

const Hero = () =>{
    return(
        <section id="home" className="lg:min-h-screen">
            <div className ="" >
                <div className='hidden lg:flex absolute right-0 bottom-12 z-20 animate__animated animate__fadeInUp'>
                    <svg width="386" height="12" viewBox="0 0 386 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.226471 6L6 11.7735L11.7735 6L6 0.226497L0.226471 6ZM443 5L6 5V7L443 7V5Z" fill="white"/>
                    </svg>
                </div>
                <div className="lg:min-h-screen w-full flex flex-wrap px-4 pt-28 lg:px-20 lg:pt-0 md:px-4 md:pt-12" >
                    <div className='absolute z-10 left-0 top-0 py-16 px-16 lg:py-28 lg:px-28 rounded-br-full blur-bg2 bg-purple500'></div>
                    <div className="w-full z-40 md:w-1/2 lg:w-1/2 flex items-center pl-2" >
                        <div className='animate__animated animate__fadeInUp'>
                            <div className='flex flex-wrap items-center'>
                                <h1 className="text-start font-medium text-slate-300 text-2xl lg:text-2xl mr-4">Hello, I Am Surya Persada</h1>
                                <svg width="86" height="7" viewBox="0 0 86 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M85.887 3.50001L83.0002 0.613256L80.1135 3.50001L83.0002 6.38676L85.887 3.50001ZM0.000244097 4L83.0002 4.00001L83.0002 3.00001L0.000244184 3L0.000244097 4Z" fill="white" fill-opacity="0.5"/>
                                </svg>
                            </div>
                            <div className='flex flex-wrap mt-4 border-l border-double border-slate-500'>
                                <div className = 'pl-6 lg:pl-12' >
                                    <h1 className="text-start text-5xl lg:text-6xl font-bold text-white">UI & UX Designer</h1>
                                    <p className="text-start mt-6 text-slate-300 text-md lg:text-lg">A passionate UI/UX Designer based in Indonesia. Welcome to my portfolio world.</p>
                                    <div className="flex justify-start mt-8 animate__animated animate__fadeInUp">
                                        <a href='#portofolio' className="rounded-2xl btn py-2.5 px-6 capitalize transition ease-in-out hover:-translate-y-1 duration-150 shadow-btn shadow-blue500" >
                                            View Portofolio
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='hidden absolute left-0 bottom-6 lg:flex items-center mt-12 animate__animated animate__fadeInUp'>
                            <svg width="119" height="2" viewBox="0 0 119 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M119 1L-4.52995e-06 1" stroke="white" stroke-width="2"/>
                            </svg>
                            <div className='mx-2'>
                                <BtnSosmed/>
                            </div> 
                            <svg width="68" height="12" viewBox="0 0 68 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M67.7735 6L62 0.226497L56.2265 6L62 11.7735L67.7735 6ZM62 5L0 5V7L62 7V5Z" fill="white"/>
                            </svg>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 lg:w-1/2 flex justify-center items-center md:pt-0" >
                        <div className='absolute right-0 -bottom-52 lg:-bottom-40 md:bottom-0 py-24 px-12 lg:py-48 lg:px-24 rounded-l-full blur-bg1 lg:blur-bg3 bg-purple500'></div>
                        <div className="w-full flex justify-center items-center lg:items-center md:items-center relative" >
                            <div className='absolute -left-12 mt-24 lg:mt-40 py-24 px-24 lg:py-40 lg:px-40 rounded-full blur-bg2 lg:blur-bg3 bg-blue500'></div>
                            <div className="absolute bg-card z-20 px-2.5 py-2 border-2 border-indigo-900/50 rounded-2xl top-40 left-4 lg:top-56 lg:left-12 animate__animated animate__fadeInUp">
                                <img src={Point} width='50' alt='Point'/>
                            </div>
                            <img src={Patern} className="absolute -z-0 opacity-50 scale-100 -left-20 lg:top-12" alt='patern'/>
                            <img src={Figma} className="lg:flex hidden absolute -z-0 scale-75 lg:scale-100 md:scale-75 -right-12 top-0 lg:right-0 lg:top-24 animate__animated animate__fadeInUp" alt='figma'/>
                            <div className='animate__animated animate__fadeInUp'>
                                <img src={Figma} className="lg:hidden flex absolute -z-0 scale-75 lg:scale-100 md:scale-75 -right-12 top-0 lg:right-0 lg:top-24" alt='figma'/>
                                <img src={Profile} className="scale-75 lg:scale-95 md:scale-75 pt-8 lg:pt-16 md:pt-0" alt='profile'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Hero;