import logo from '../assets/Harsi.svg';
import { Link } from "react-scroll";
import { BiHomeSmile } from 'react-icons/bi';
import { HiOutlineUser } from 'react-icons/hi';
import { MdWorkspacesOutline } from 'react-icons/md';
import { IoNewspaperOutline } from 'react-icons/io5';
import React, {useState, useEffect} from 'react'


const navigation = [
  { name: 'Home', href: 'home', current: true},
  { name: 'About', href: 'about', current: false},
  { name: 'Portofolio', href: 'portofolio', current: false},
  { name: 'Awards', href: 'awards', current: false},
]

const Navbar = () => {
	const [prevScrollPos, setPrevScrollPos] = useState(0);
	const [visible, setVisible] = useState(true)

	const handleScroll = () => {
		const currentScrollPos = window.scrollY

		if(currentScrollPos > prevScrollPos){
			setVisible(false)
		}else{
			setVisible(true)
		}

		setPrevScrollPos(currentScrollPos)
	}

	useEffect( () => {
		window.addEventListener('scroll', handleScroll);

		return () => window.removeEventListener('scroll', handleScroll)
	})

  return (
    <div>
		<div class="fixed z-50 top-0 flex w-full">
			<div className="w-full px-4 py-2 lg:px-20 items-center bg-gradient-to-b from-slate-50/10 shadow-sm backdrop-blur-lg">
				<div className='flex justify-between'>
					<div className="flex justify-center">
						<a to='/' className='flex items-center'>
							<img src={logo} alt="Logo" className='scale-75 lg:scale-75'/>
						</a>
					</div>
					<div className="hidden lg:flex md:flex items-center space-x-12">
						<ul className="flex justify-between space-x-16">
							{navigation.map((item) => (
							<li>
								<Link activeClass="active text-slate-50 font-medium border-b-2 border-white transition"
									spy={true}
									smooth={true}
									offset={-100}
									duration={500} 
									className=' hover:text-blue500 text-slate-50 py-3' 
									to={item.href}
								>
									{item.name}
								</Link>
							</li>
							))}
						</ul>
					</div>
					<div className="flex items-center">
					<a href="mailto:desuryaa1@gmail.com" className = "rounded-2xl outline outline-offset-0 outline-1 outline-white py-1.5 px-4 lg:py-2 lg:px-6 font-medium text-white transition ease-in-out hover:-translate-y-1 hover:scale-100 duration-300 hover:opacity-80 hover:shadow-btn hover:shadow-purple500 active:bg-darkblue" >
						Say Hi!
					</a>
					</div>
				</div>
			</div>
		</div>
		<div className='lg:hidden md:hidden fixed bottom-2 z-50 w-full flex justify-center'>
			<div className="w-auto flex justify-center rounded-full bg-gradient-to-b from-slate-50/10 shadow-lg backdrop-blur-lg">
				<ul className="flex flex-wrap mx-2">
					<li className='mx-6 py-4 text-gray-500 scale-125'>
						<Link
						activeClass="active text-slate-50 transition-all"
						spy={true}
						smooth={true}
						offset={-100}
						duration={500} 
						to="home"
					>
						<BiHomeSmile className='scale-125'/>
						</Link>
					</li>
					<li className='mx-6 py-4 text-gray-500 scale-125'>
						<Link
						activeClass="active text-slate-50 transition-all"
						spy={true}
						smooth={true}
						offset={-100}
						duration={500}
						to="about" 
					>
						<HiOutlineUser className='scale-125'/>
						</Link>
					</li>
					<li className='mx-6 py-4 text-gray-500 scale-125'>
						<Link
						activeClass="active text-slate-50 transition-all"
						spy={true}
						smooth={true}
						offset={-100}
						duration={500} 
						to="portofolio"
					>
						<MdWorkspacesOutline className='scale-125'/>
						</Link>
					</li>
					<li className='mx-6 py-4 text-gray-500 scale-125'>
						<Link
						activeClass="active text-slate-50 transition-all"
						spy={true}
						smooth={true}
						offset={-100}
						duration={500} 
						to="blog"
					>
						<IoNewspaperOutline className='scale-125'/>
						</Link>
					</li>
				</ul>
			</div>
		</div>
    </div>
  );
};
export default Navbar;
