import CardAbout from './CardAbout';
import Illustrator from '../assets/images/illustrator.svg';
import Frontend from '../assets/images/frontend.svg';
import UI from '../assets/images/ui.svg';
import Patern from '../assets/images/patern.svg';
import CV from '../assets/CV_I GUSTI MADE AGUNG ARY SURYA PERSADA.pdf';

const About = () =>{
    return(
        <section id="about" className="lg:min-h-screen lg:mt-12">
            <div className ="" >
                    <div data-aos="fade-up" className="w-full lg:hidden md:hidden flex flex-wrap px-2 pb-12 aos-init aos-animate">
                        <div className="carousel rounded-box py-3">
                            <div  className="carousel-item mx-2">
                                <CardAbout image={UI} title={"UI/UX Design"} desc={"Interested and have experience in creating UI/UX designs using the Figma application"}/>
                            </div> 
                            <div className="carousel-item mx-2">
                                <CardAbout image={Frontend} title={"Frontend Design"} desc={"Interested and have experience in creating a Web Design using Tailwind CSS, etc"}/>
                            </div> 
                            <div className="carousel-item mx-2">
                                <CardAbout image={Illustrator} title={"Illustrator"} desc={"Interested in creating Digital Art using Adobe Illustrator"}/>
                            </div> 
                        </div>
                    </div>
                <div className="lg:min-h-screen md:full w-full flex flex-wrap px-4 lg:px-20 md:px-8" >
                    <div className="w-full md:w-1/2 lg:w-1/2 hidden lg:flex md:flex justify-center items-center" >
                        <img src={Patern} class="absolute opacity-30 scale-100" alt='abstract'/>
                        <div className='absolute flex items-center py-16 px-16 lg:py-28 lg:px-28 rounded-full blur-bg2 bg-purple500'></div>
                        <div data-aos="fade-right" className="z-40 pr aos-init aos-animate" >
                            <CardAbout image={Frontend} title={"Frontend Design"} desc={"Interested and have experience in creating  a Web Design using Tailwind CSS, etc"}/>
                            <CardAbout image={Illustrator} title={"Illustrator"} desc={"Interested in creating Digital Art using Adobe Illustrator"}/>
                        </div>
                        <div data-aos="fade-up" className="z-40 pr-4 aos-init aos-animate" >
                            <CardAbout image={UI} title={"UI/UX Design"} desc={"Interested and have experience in creating UI/UX designs using the Figma application"}/>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 lg:w-1/2 flex items-center" >
                        <div>
                            <div data-aos="fade-up" className='flex flex-wrap items-center aos-init aos-animate'>
                                <h1 className="text-start font-medium text-slate-300 text-xl lg:text-2xl mr-4">Let Me Introduce Myself</h1>
                                <svg width="86" height="7" viewBox="0 0 86 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M85.887 3.50001L83.0002 0.613256L80.1135 3.50001L83.0002 6.38676L85.887 3.50001ZM0.000244097 4L83.0002 4.00001L83.0002 3.00001L0.000244184 3L0.000244097 4Z" fill="white" fill-opacity="0.5"/>
                                </svg>
                            </div>
                            <div data-aos="fade-up" className='flex flex-wrap mt-4 border-l border-double border-slate-500 aos-init aos-animate'>
                                <div className = 'pl-6 lg:pl-12' >
                                    <h1 className="text-start text-3xl lg:text-4xl font-bold text-white">About Me</h1>
                                    <p className="text-start mt-2 lg:mt-6 text-slate-300 text-md lg:text-lg">I am an active student in Information Technology at Udayana University who is enthusiastic to seeking new experiences. Actively participate in organization and committee activities on campus. Have an interest and experience in design and programming, such as creating UI/UX Design and Front End Web Development.</p>
                                    <div className="flex justify-start mt-8 animate__animated animate__fadeInUp">
                                        <a href={CV} download className="rounded-2xl btn py-2.5 px-6 capitalize transition ease-in-out delay-100 hover:-translate-y-1 duration-200 shadow-btn shadow-blue500" >
                                            Download CV
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;