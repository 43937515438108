import { Routes, Route} from "react-router-dom";
import Navbar from './components/Navbar';
import Beranda from './pages/Beranda';
import React, {useEffect} from 'react';
import AOS from 'aos';
import './App.css';
import 'aos/dist/aos.css';
import 'animate.css';

function App() {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <div className="App">
      <Navbar/>
      <Routes>
        <Route path="/" element={<Beranda />}/>
      </Routes>
    </div>
  );
}

export default App;
