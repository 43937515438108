import React, {useState} from 'react';
import {AiOutlineArrowUp} from 'react-icons/ai';
  
const ScrollButton = () =>{
  
  const [visible, setVisible] = useState(false)
  
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true)
    } 
    else if (scrolled <= 300){
      setVisible(false)
    }
  };
  
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  
  window.addEventListener('scroll', toggleVisible);
  
  return (
    <button data-aos="fade-up"  className='aos-init aos-animate py-2 px-3 mb-4 border-2 border-gray-300 bg-background bg-opacity-70 shadow-lg rounded-full transition ease-in-out hover:-translate-y-1 hover:scale-100 duration-300 hover:shadow-btn hover:shadow-blue500'>
      <AiOutlineArrowUp className='text-white scale-150' onClick={scrollToTop} 
      style={{display: visible ? 'inline' : 'none'}} />
    </button>
  );
}
  
export default ScrollButton;