//import Button from "../components/button/BtnPrimary";
import CoverMomCare from "../assets/images/CoverMomCare.webp";
import CoverAniton from "../assets/images/CoverAnition.webp";
import CoverMaiDental from "../assets/images/CoverMaiDental.webp";
import CoverDSkill from "../assets/images/CoverDSkill.webp";
import CardAwards from "./CardAwards";

const portofolio = [
  {
    src: CoverAniton,
    href: "https://docs.google.com/presentation/d/1BjgCs8miIDEOngRxZgcjScb2Cfys9BOu/edit?usp=share_link&ouid=103346527611430527358&rtpof=true&sd=true",
    title: "CoverAniton",
    tag: "UI/UX Design",
    desc: "Second Place of UI/UX Design Competition TechArt Festival 2022.",
    alt: "CoverAniton",
  },
  {
    src: CoverMaiDental,
    href: "https://docs.google.com/presentation/d/1ouPz2XDQFWNCE7w0GTq9R5Fy4SXFh1SY/edit?usp=sharing&ouid=103346527611430527358&rtpof=true&sd=true",
    title: "CoverMaiDental",
    tag: "UI/UX Design",
    desc: "Third Place of National Web Design Competition Invention 2022.",
    alt: "CoverMaiDental",
  },
  {
    src: CoverDSkill,
    href: "https://docs.google.com/presentation/d/1Ugi_vQBRGEH0B2Ul3t-JOZxVU3s2bg37/edit?usp=sharing&ouid=103346527611430527358&rtpof=true&sd=true",
    title: "DSkill",
    tag: "UI/UX Design",
    desc: "Finalist of National UX Design Competition IT Convert 2022.",
    alt: "DSkill",
  },
  {
    src: CoverMomCare,
    href: "https://docs.google.com/presentation/d/1C-1lh93RzL4Fh4TLG8UWyz0a2lP5XkCE/edit?usp=sharing&ouid=103346527611430527358&rtpof=true&sd=true",
    title: "MomCare",
    tag: "UI/UX Design",
    desc: "Finalist of National UI/UX Competition Fasilkom Fest 2022.",
    alt: "MomCare",
  },
  
];

const Awards = () => {
  return (
    <section id="awards" className="lg:min-h-screen mt-24 lg:mt-12">
      <div className="flex flex-wrap justify-center">
        <div className="absolute mt-52 py-24 px-24 lg:py-40 lg:px-40 rounded-full blur-bg3 bg-blue500"></div>
      </div>
      <div className="lg:min-h-screen mx-2 lg:px-20">
        <div data-aos="fade-up" className="justify-center aos-init aos-animate">
          <h1 className="text-3xl lg:text-4xl font-bold text-white mt-3 mb-6">
            Honors & Awards
          </h1>
          <div className="flex justify-center mt-2 text-gray-500">
            <svg
              width="94"
              height="3"
              viewBox="0 0 94 3"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="0.5"
                y1="1.78088"
                x2="93.5"
                y2="1.78088"
                stroke="url(#paint0_linear_120_69)"
                stroke-width="2"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_120_69"
                  x1="0.5"
                  y1="3.78099"
                  x2="93.5"
                  y2="3.78099"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="white" stop-opacity="0" />
                  <stop
                    offset="0.511639"
                    stop-color="white"
                    stop-opacity="0.5"
                  />
                  <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
        <div
          data-aos="fade-up"
          className="flex flex-wrap justify-center aos-init aos-animate"
        >
          <div className="lg:hidden carousel rounded-box py-6 pb-16">
            {portofolio.map((item) => (
              <div className="carousel-item mx-2 lg:mx-0">
                <CardAwards
                  href={item.href}
                  image={item.src}
                  title={item.title}
                  tag={item.tag}
                  desc={item.desc}
                  alt={item.alt}
                />
              </div>
            ))}
          </div>
          <div className="hidden lg:flex flex-wrap justify-center pb-16">
            {portofolio.map((item) => (
              <div className="my-4">
                <CardAwards
                  href={item.href}
                  image={item.src}
                  title={item.title}
                  tag={item.tag}
                  desc={item.desc}
                  alt={item.alt}
                />
              </div>
            ))}
          </div>
        </div>
        {/* <div data-aos="fade-up" className="mt-2 pb-12 aos-init aos-animate">
          <Button button={"See More..."} link={"#"} />
        </div> */}
      </div>
    </section>
  );
};

export default Awards;
