const CardAwards = ({ href, image, title, tag, desc, alt }) => {
    return (
      <a href={href} target="blank">
        <div className="card w-80 bg-gradient-to-b from-slate-50/25 border-2 border-indigo-900/50 shadow-md rounded-3xl lg:mx-5 md:mx-2 mt-4 transition ease-in-out hover:-translate-y-1 duration-300 hover:shadow-2btn hover:shadow-blue500/50">
          <div className="">
            <img
              className="object-cover w-full rounded-3xl px-2 pt-2"
              src={image}
              alt={alt}
            />
          </div>
          <div className="my-6 mx-4 items-center text-center">
            <p className="text-slate-100 text-md">{desc}</p>
          </div>
        </div>
      </a>
    );
  };
  
  export default CardAwards;
  