import logo from '../assets/Harsi.svg';
import BtnSosmed from './button/BtnSosmed';

const Footer = () => {
    return (
        <div className='bg-gradient-to-b from-slate-50/5 backdrop-blur-md rounded-t-4xl'>
            <div className="w-full flex flex-wrap py-4 z-10 text-black lg:px-24">
                <div className='w-full lg:w-1/2 flex flex-wrap justify-center lg:justify-start'>
                    <img src={logo} alt="Logo" className='scale-75'/> 
                </div> 
                <div className = 'w-full lg:w-1/2 mt-6 lg:mt-0' >
                    <div className = 'flex flex-wrap justify-center lg:justify-end' >
                        <BtnSosmed/>
                    </div> 
                </div> 
            </div>
            <div>
                <p className='text-slate-50 py-2 pb-16 lg:pb-2 md:pb-2 border-t border-white/10'>©2023 Harsi. All rights reserved.</p>
            </div>
        </div>
    );
  };
  
  export default Footer;
  